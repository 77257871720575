// Mobile Menu
document.addEventListener('DOMContentLoaded', function () {
    let mobileMenu = document.getElementById('mobileMenu'),
        openMenuBtn = document.getElementById('openMobileMenu'),
        closeMenuBtn = document.getElementById('closeMobileMenu');

    if (openMenuBtn && closeMenuBtn) {
        openMenuBtn.addEventListener('click', function (evt) {
            evt.preventDefault();

            mobileMenu.classList.remove('duration-300', 'ease-in-out', 'pointer-events-none');
            mobileMenu.classList.add('duration-300', 'ease-in-out', 'opacity-100', 'scale-100');
            mobileMenu.classList.remove('opacity-0', 'scale-95');
        });

        closeMenuBtn.addEventListener('click', function (evt) {
            evt.preventDefault();

            mobileMenu.classList.remove('duration-300', 'ease-in-out');
            mobileMenu.classList.add('duration-300', 'ease-in-out', 'opacity-0', 'scale-95', 'pointer-events-none');
            mobileMenu.classList.remove('opacity-100', 'scale-100');
        });
    }
})

// Navbar Header
document.addEventListener('DOMContentLoaded', () => {
    const header = document.getElementById('header')
    if (header) {
        let headerOtherBtn = document.getElementById('headerOtherBtn'),
            otherFlyOutDiv = document.getElementById('headerOtherFlyOutDiv')

        if (headerOtherBtn && otherFlyOutDiv) {
            btnToggleHideOutside(headerOtherBtn, otherFlyOutDiv)
        }
    }
})

const btnToggleHideOutside = function (trigger, target) {
    let toggled = false

    const onClickOutside = function (event) {
        if (!trigger.contains(event.target) && !target.contains(event.target)) {
            return toggleFunc(event)
        }
    }

    const toggleFunc = function (event) {
        toggled = !toggled
        if (toggled) {
            target.classList.remove('ease-in', 'duration-150', 'pointer-events-none')
            target.classList.add('ease-out', 'duration-200', 'opacity-100', 'translate-y-0')
            target.classList.remove('opacity-0', 'translate-y-1')
            document.addEventListener('click', onClickOutside)
        } else {
            target.classList.remove('ease-out', 'duration-200')
            target.classList.add('ease-in', 'duration-150', 'opacity-0', 'translate-y-1', 'pointer-events-none')
            document.removeEventListener('click', onClickOutside)
            target.classList.remove('opacity-100', 'translate-y-0')
        }
    }

    trigger.addEventListener('click', toggleFunc)
}
